import type { SupabaseClient } from '@supabase/supabase-js';
import type { Database, Tables } from '$lib/db';
import type {
	Location,
	Listing,
	NearbyListing,
	ListingSearchResult,
	Category,
	Profile
} from '$lib/types';

export type ListingRow = Tables<'listings'>;
export type ListingInsert = Database['public']['Tables']['listings']['Insert'];
export type ListingUpdate = Database['public']['Tables']['listings']['Update'];

export default class ListingsRepository {
	private supabase: SupabaseClient;

	constructor(supabase: SupabaseClient) {
		this.supabase = supabase;
	}

	async getById(id: number): Promise<Listing | null> {
		return this.supabase
			.from('detailed_listings_view')
			.select(`*`)
			.eq('id', id)
			.maybeSingle()
			.then(({ data, error }) => {
				if (error) {
					console.error('Error retrieving listing:', error);
					throw error;
				}

				const location: Location = {
					place: data.location_name,
					lat: data.location_lat,
					lng: data.location_lng
				};

				let category: Category = {
					id: data.category_id,
					name: data.category_name,
					slug: data.category_slug,
					path: data.category_path
				};

				let profile: Profile = {
					id: data.profile_id,
					fullName: data.full_name,
					username: data.username
				};

				return {
					id: data.id,
					title: data.title,
					description: data.description,
					createdBy: profile,
					category: category,
					location: location,
					contactInfo: data.contact_info ? data.contact_info : {},
					isFavorite: false
				};
			});
	}

	async listingInView(
		min_lat: number,
		min_long: number,
		max_lat: number,
		max_long: number
	): Promise<NearbyListing[]> {
		const { data, error } = await this.supabase
			.rpc('listings_in_view', {
				min_lat,
				min_long,
				max_lat,
				max_long
			})
			.returns<NearbyListing[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data;
	}

	async searchByTitleAndDescription(query: string): Promise<ListingSearchResult[]> {
		const { data, error } = await this.supabase
			.rpc('search_listings', {
				prefix: query
			})
			.returns<ListingSearchResult[]>();

		if (error) {
			throw new Error(error.message);
		}

		return data;
	}
}
